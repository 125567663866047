@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/**:focus {*/
/*  outline: none;*/
/*}*/
/* width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

::-webkit-scrollbar-button {
  display: none;
}

body {
  @apply text-smena_text font-roboto;
}

@layer components {
  /* Text styles */

  .H1 {
    @apply font-normal text-5xl leading-[56px];
  }
  .H2 {
    @apply font-normal text-[34px] leading-[42px] tracking-[0.25px];
  }
  .H3 {
    @apply font-normal text-[24px] leading-[32px];
  }
  .H4 {
    @apply font-medium text-[20px] leading-[32px] tracking-[0.15px];
  }
  .Subtitle1 {
    @apply font-medium text-[16px] leading-[28px] tracking-[0.15px];
  }
  .Subtitle2 {
    @apply font-medium text-[14px] leading-[22px] tracking-[0.1px];
  }
  .Body1 {
    @apply font-normal text-[15px] leading-[24px] tracking-[0.15px];
  }
  .Body2 {
    @apply font-normal text-[13px] leading-[20px] tracking-[0.15px];
  }
  .Table {
    @apply font-roboto font-normal text-[14px] leading-[20px] tracking-[0.15px];
  }
  .Table-small {
    @apply font-roboto font-normal text-[13px] leading-[20px] tracking-[0.15px];
  }
  .Table-small-bold {
    @apply font-roboto font-bold text-[13px] leading-[20px] tracking-[0.15px];
  }
  .Table-H1 {
    @apply font-roboto font-medium text-[14px] leading-[20px] tracking-[0.65px];
  }
  .Button1 {
    @apply font-bold text-[14px] leading-[24px];
  }
  .Button2 {
    @apply font-bold text-[12px] leading-[24px];
  }
  .Button3 {
    @apply font-bold text-[10px] leading-[16px];
  }
  .Caption {
    @apply font-normal text-[12px] leading-[20px] tracking-[0.4px];
  }
  .Caption-numbers {
    @apply font-roboto font-medium text-[12px] leading-[24px] tracking-[0.1px];
  }
  .Caption-small {
    @apply font-normal text-[10px] leading-[17px] tracking-[0.4px];
  }
  .Overline {
    @apply font-normal text-[12px] leading-[32px] tracking-[1px] uppercase;
  }
  .Tag {
    @apply font-roboto font-medium text-[10px] leading-[17px] tracking-[0.4px];
  }

  .ChartNumber {
    @apply font-bold text-[24px] leading-[32px];
  }

  .ChartBigNumber {
    @apply font-bold text-[40px] leading-[53px];
  }

  .StoryText {
    @apply font-sfProSemibold text-[11px] leading-[12px] tracking-[-0.41px];
  }

  .StoryTime {
    @apply font-sfPro text-[15px] leading-[17.9px] tracking-[-0.3px] text-smena_white;
  }

  .StoryTitle {
    @apply font-sfProDisplay text-[27px] font-bold leading-[28px] tracking-[0.8px] text-smena_white;
  }

  .StoryMessage {
    @apply font-sfPro text-[17px] leading-[22px] tracking-[-0.41px] text-smena_white;
  }

  .StoryButton {
    @apply font-sfPro text-[13px] leading-[18px] tracking-[-0.08px];
  }

  .StoryQuestion {
    @apply font-sfPro text-[15px] leading-[20px] tracking-[-0.24px];
  }

  .btn-split {
    @apply bg-primary text-white font-inter normal-case flex items-center justify-center
    cursor-pointer overflow-hidden relative z-10 border-smena_white border-solid
    duration-[0.2s] font-bold text-[14px] leading-[24px]
    disabled:opacity-50 disabled:cursor-not-allowed !important;
  }

  .btn-split__left {
    @apply rounded-l-lg border px-6 !important;
  }

  .btn-split__right {
    @apply rounded-r-lg border pt-1 px-2 !important;
  }

  .dot-legend {
    @apply rounded-full w-[14px] h-[14px] block;
  }

  .smena-sidebar .ant-picker-dropdown {
    @apply fixed;
  }

  /*.table__row:nth-child(even) {*/
  /*  @apply bg-smena_gray-2 hover:bg-smena_bb-background;*/
  /*}*/
  .table__row {
    @apply bg-smena_white hover:bg-smena_bb-background_light;
  }

  /*.table__row:nth-child(even) .active-column {*/
  /*  @apply bg-smena_bb-background;*/
  /*}*/
  .table__row .active-column {
    @apply bg-smena_bb-background_light;
  }

  .link {
    @apply text-primary hover:underline;
  }

  .probationStartPeriod {
    @apply ml-[1px] border-l rounded-l-md;
  }

  .probationStartPeriod .moderation__cell-content {
    @apply ml-[3px];
  }

  .probationFullPeriod {
    @apply mx-[1px] border rounded-md;
  }

  .probationFullPeriod .moderation__cell-content {
    @apply mx-[3px];
  }

  .probationPeriod {
    @apply px-[2.5px] border-y  my-[2px];
  }

  .probationEndPeriod {
    @apply mr-[1px] border-r rounded-r-md;
  }

  .probationEndPeriod .moderation__cell-content {
    @apply mr-[3px];
  }

  /*.moderation__cell {*/
  /*  @apply w-[45px]*/
  /*}*/
  .step-title {
    @apply after:absolute after:top-3 after:h-px after:w-[99999px] after:block;
  }

  .step-title::after {
    inset-inline-start: 100%;
  }

  /*tables*/
  table,
  .divTable {
    border: none;
    width: fit-content;
  }

  .tr {
    display: flex;
  }

  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  .resizer.isResizing {
    background: blue;
    opacity: 1;
  }

  @media (hover: hover) {
    .resizer {
      opacity: 0;
    }

    *:hover > .resizer {
      opacity: 1;
    }
  }

  .facility-pin {
    @apply inline-flex gap-x-[2px] absolute -left-[19px] -top-[47px];
  }

  .search-pin {
    @apply inline-block h-[38px] w-[30px] absolute -left-[16px] -top-[38px];
  }

  .facility-pin__placemark {
    @apply inline-block rounded-t-full rounded-br-full
    bg-smena_white w-[40px] h-[40px] overflow-hidden relative
    transform -rotate-45 shadow-smena;
  }

  .facility-pin__image {
    @apply w-[30px] h-[30px] transform rotate-45 rounded-full
    absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
    bg-contain;
  }

  .facility-pin__description {
    @apply p-1 flex flex-col max-w-[160px] w-max rounded;
  }
}

.ReactCrop__child-wrapper {
  @apply h-full w-full;
}

[type='radio']:disabled {
  @apply cursor-not-allowed;
}

.btn-primary_big,
.btn-primary,
.btn-primary_small,
.btn-primary_extra_small,
.btn-secondary_big,
.btn-secondary,
.btn-secondary_small,
.btn-secondary_extra_small,
.btn-reject_big,
.btn-reject,
.btn-reject_small,
.btn-reject_extra_small,
.btn-reject_secondary_big,
.btn-reject_secondary,
.btn-reject_secondary_small,
.btn-reject_secondary_extra_small,
.btn-stroke_reject_big,
.btn-stroke_reject,
.btn-stroke_reject_small,
.btn-stroke_reject_extra_small,
.btn-stroke_big,
.btn-stroke,
.btn-stroke_small,
.btn-stroke_extra_small {
  @apply font-inter rounded-lg cursor-pointer overflow-hidden
  relative z-10 transition font-bold leading-[24px]
  disabled:opacity-50 disabled:cursor-not-allowed;
}

.btn-reject_secondary_big,
.btn-reject_secondary,
.btn-reject_secondary_small,
.btn-reject_secondary_extra_small {
  @apply bg-smena_red-extra_light text-smena_red hover:bg-smena_red-light active:bg-smena_red-light;
}

.btn-reject_big,
.btn-reject,
.btn-reject_small,
.btn-reject_extra_small {
  @apply bg-smena_red text-white hover:bg-smena_red-light active:bg-smena_red-dark;
}

.btn-primary_big,
.btn-primary,
.btn-primary_small,
.btn-primary_extra_small {
  @apply bg-primary text-white hover:bg-primary-secondary active:bg-primary-dark;
}

.btn-primary_big,
.btn-reject_big,
.btn-reject_secondary_big,
.btn-stroke_reject_big,
.btn-secondary_big,
.btn-stroke_big {
  @apply text-[14px] px-9 py-1 h-8;
}

.btn-primary,
.btn-reject,
.btn-reject_secondary,
.btn-stroke_reject,
.btn-secondary,
.btn-stroke {
  @apply text-[14px] py-1 px-6 h-8;
}

.btn-primary_small,
.btn-reject_small,
.btn-reject_secondary_small,
.btn-stroke_reject_small,
.btn-secondary_small,
.btn-stroke_small {
  @apply text-[12px] py-1 px-3 h-8;
}

.btn-primary_extra_small,
.btn-reject_extra_small,
.btn-reject_secondary_extra_small,
.btn-secondary_extra_small,
.btn-stroke_reject_extra_small,
.btn-stroke_extra_small {
  @apply text-[10px] px-[6px] h-6;
}

.nav-link,
.nav-link_active,
.sub_nav-link,
.sub_nav-link_active {
  @apply flex p-2 truncate font-medium text-[14px] leading-[22px] tracking-[0.1px];
}

.nav-link,
.nav-link_hidden,
.sub_nav-link {
  @apply bg-smena_white hover:bg-primary-light hover:text-smena_white;
}

.nav-link_active,
.nav-link_active_margin,
.nav-link_active_hidden,
.nav-link_active_margin_hidden,
.sub_nav-link_active {
  @apply bg-primary text-smena_white;
}

.nav-link_margin,
.nav-link_active_margin,
.nav-link_margin_hidden,
.nav-link_active_margin_hidden {
  @apply mb-10;
}
.nav-link_hidden,
.nav-link_active_hidden,
.nav-link_margin_hidden,
.nav-link_active_margin_hidden {
  @apply flex p-2 rounded-full text-xl w-max;
}

.sub_nav-link,
.sub_nav-link_active {
  @apply ml-[10px];
}

.btn-secondary_big,
.btn-secondary,
.btn-secondary_small,
.btn-secondary_extra_small {
  @apply bg-smena_bb-background text-primary hover:bg-smena_bb-border_light active:bg-smena_bb-border;
}

.btn-stroke_big,
.btn-stroke,
.btn-stroke_small,
.btn-stroke_extra_small {
  @apply bg-white text-primary hover:bg-smena_bb-background active:bg-smena_bb-border;
  border: 1px solid rgba(27, 96, 227, 0.3);
}

.btn-stroke_reject_big,
.btn-stroke_reject,
.btn-stroke_reject_small,
.btn-stroke_reject_extra_small {
  @apply bg-smena_white text-smena_red hover:bg-smena_red-extra_light active:bg-smena_red-light;
  border: 1px solid rgba(255, 85, 95, 0.3);
}

.label-primary {
  @apply font-inter truncate;
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.1px;
}

.label-checkbox {
  @apply font-inter block Body1;
}

.textarea-primary,
.textarea-error,
.input-primary,
.input-error {
  @apply block w-full Body2 border rounded-lg
  py-[6px] tracking-[0.15px]
  disabled:bg-smena_gray-5 disabled:cursor-not-allowed;
}

.react-dadata__input {
  @apply h-8 block w-full Body2 border rounded-lg
  p-[6px] tracking-[0.15px] border-smena_gray-40
  disabled:bg-smena_gray-5 disabled:cursor-not-allowed
  hover:border-primary focus:ring-0 focus:border-primary focus:border-1.5;
}

.react-dadata__container {
  @apply relative w-full;
}

.react-dadata__suggestions {
  @apply overflow-scroll max-h-[250px];
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.1);
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: calc(100% + 8px);
  z-index: 30;
}

.react-dadata__suggestion {
  @apply Body1 text-smena_text-helper bg-none border-none block text-left p-[7px] w-full overflow-auto max-h-[250px];
}

.react-dadata__suggestion:has(p) {
  @apply hidden;
}

.react-dadata__suggestion--line-through {
  text-decoration: line-through;
}

.react-dadata__suggestion-subtitle {
  color: #777;
  font-size: 14px;
  margin-top: 4px;
}

.react-dadata__suggestion-subtitle-item {
  display: inline-block;
  margin-bottom: 4px;
  margin-right: 16px;
}

.react-dadata__suggestion-subtitle-item:last-child {
  margin-right: 0;
}

.react-dadata__suggestion--current {
  background-color: rgba(0, 124, 214, 0.15);
}

.react-dadata__suggestion:hover {
  background-color: rgba(0, 124, 214, 0.1);
}

.react-dadata mark {
  background: none;
}

.react-dadata__suggestion .react-dadata--highlighted {
  @apply text-smena_text;
}

.input-primary,
.input-error {
  @apply h-8;
}

.textarea-primary,
.textarea-error {
  @apply h-[32px] border-smena_gray-40;
}

.documents-textarea {
  height: 50px;
}

.input-primary {
  @apply border-smena_gray-40;
}

.radio-primary {
  @apply p-3;
}

.input-error {
  @apply border-smena_red-mark focus:ring-0 focus:border-smena_red focus:border-1.5;
}
.textarea-error {
  @apply border-smena_red-mark focus:ring-0 focus:border-smena_red focus:border-1.5;
}

.input-primary:enabled::placeholder,
.textarea-primary::placeholder {
  @apply text-smena_text-helper;
}

.select-primary,
.select-error,
.single-select {
  @apply block w-full border border-solid Body2 bg-white rounded-lg
  disabled:cursor-not-allowed disabled:bg-smena_gray-5
  hover:border-primary focus:ring-0 focus:border-primary focus:border-1.5;
}

.select-primary,
.select-error,
.single-select {
  @apply disabled:cursor-not-allowed;
}

.select-primary {
  @apply border-smena_gray-40
  disabled:bg-smena_gray-5;
}

.select-error {
  @apply border-smena_red-mark
  focus:ring-0 focus:border-smena_red focus:border-1.5;
}

.input-primary:enabled,
.select-primary:enabled {
  @apply hover:border-primary focus:ring-0 focus:border-primary focus:border-1.5;
}

.checkbox-primary {
  @apply block w-[18px] h-[18px] rounded-md text-primary border-transparent bg-smena_bb-background
  hover:bg-smena_bb-background_light active:bg-smena_bb-border
  disabled:bg-smena_gray-50 disabled:opacity-50 disabled:text-smena_gray-50 disabled:cursor-not-allowed;
}

.max-w-mac {
  max-width: 1920px;
}

.schedule,
.shifts {
  max-width: 1385px;
}

.schedule__wrapper,
.shifts__wrapper {
  @apply bg-smena_white shadow-smena rounded-lg w-full px-6 py-5;
}

.schedule__calendar,
.shifts__calendar {
  overflow: auto;
  border-radius: 8px;
  min-height: 610px;
}

.schedule__wrapper .rbc-month-view,
.shifts__wrapper .rbc-month-view {
  @apply rounded-lg;
}

.rbc-calendar {
  @apply gap-y-5 w-[900px];
}

.rbc-toolbar-label {
  text-align: right;
}

.rbc-row-segment {
  @apply flex justify-end p-0;
}

.rbc-off-range-bg {
  @apply bg-smena_gray-30 bg-opacity-40 !important;
  position: relative;
  z-index: 10;
}

.rbc-event {
  @apply mx-[5px] mb-[2px] !important;
}

.rbc-header {
  @apply font-inter text-smena_text-helper uppercase !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 157% !important;
  letter-spacing: 0.1px !important;
}

.rbc-date-cell {
  @apply text-left pl-2 font-inter !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 157% !important;
}

.rbc-date-cell {
  letter-spacing: 0.1px !important;
}

.shifts .rbc-event {
  @apply pointer-events-none;
}
.shifts .rbc-day-bg {
  @apply cursor-pointer;
}

.rbc-event {
  padding: 0 !important;
  height: 17px !important;
}

.rbc-event:focus {
  outline: none !important;
}

.rbc-show-more {
  @apply font-roboto text-smena_text-secondary bg-transparent mr-1 !important;
  font-weight: 500;
  font-size: 10px;
  line-height: 166%;
  letter-spacing: 0.4px;
}

.confirmed {
  @apply bg-smena_green-extra_light !important;
}

.wait_foreman_schedule {
  @apply bg-smena_yellow-extra_light !important;
}
.without_marks,
.wait_worker_schedule {
  @apply bg-smena_orange-extra_light !important;
}

.working_schedule,
.custom_schedule,
.working_shifts {
  @apply bg-primary-secondary !important;
}

.custom_schedule span {
  @apply text-smena_white !important;
}

.ineligible_schedule {
  @apply bg-smena_gray-5 !important;
}

.refused_schedule {
  @apply bg-smena_red-extra_light !important;
}
.internship_shift {
  @apply bg-smena_purple-light !important;
}

.other_facility {
  @apply opacity-40;
}

.add_custom_schedule {
  @apply bg-primary;
}

.rejected_shift {
  @apply bg-smena_red-extra_light !important;
}

.nonsuper_schedule {
  @apply bg-smena_bb-border_light !important;
}

.working_shifts span {
  @apply text-smena_white !important;
}

.import-error__row:nth-child(odd) {
  @apply bg-smena_gray-2;
}

.import-error__row:nth-child(even) {
  background-color: #fff;
}

.main-table {
  max-height: 550px;
  overflow-y: auto;
}

/* warning exclamation mark at toaster */

.Toastify__toast {
  @apply font-inter font-normal !important;
  font-style: normal;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
}

.Toastify__toast {
  align-items: center !important;
  padding: 0 22px !important;
}

.Toastify__close-button {
  align-self: auto !important;
}

.Toastify__toast-body {
  padding: 0 32px 0 0 !important;
}

.Toastify__toast--info .Toastify__close-button {
  opacity: 1 !important;
}

.Toastify__close-button > svg {
  width: 21px !important;
  height: 21px !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  @apply bg-smena_green-dark !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  @apply bg-primary-dark !important;
}

/* Google Play Icon */

.googlePlay {
  enable-background: new 0 0 5435.8 1604;
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #a6a6a6;
}

.st2 {
  fill: #ffffff;
}

.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}

.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_1_);
}

.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_2_);
}

.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_3_);
}

.st7 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_4_);
}

.st8 {
  opacity: 0.2;
  fill-rule: evenodd;
  clip-rule: evenodd;
  enable-background: new;
}

.st9 {
  opacity: 0.12;
  fill-rule: evenodd;
  clip-rule: evenodd;
  enable-background: new;
}

.st10 {
  opacity: 0.25;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
  enable-background: new;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.codeInput {
  @apply w-full text-center;
}
.codeInput > input[type='number'] {
  @apply font-roboto text-center text-2xl text-smena_text-secondary
  p-0 mr-2 w-10 h-12 bg-smena_white
  border-1.5 border-smena_bb-border_light rounded-md;
}

/* Payments page */

.payments-row:not(:last-child) {
  @apply mb-[10px];
}

.main-page-form {
  @apply flex flex-col bg-smena_white px-10 py-6 rounded-lg w-[500px];
}

.side-menu {
  @apply fixed top-0 bottom-0 left-0 z-[100] bg-smena_white transition-all duration-300;
}

.side-menu__content {
  @apply flex flex-col justify-between h-[calc(100vh_-_30px)];
}

.open_menu {
  @apply w-full md:w-[calc(100%_-_56px)] md:left-[56px];
}
.close_menu {
  @apply md:w-[calc(100%_-_260px)] md:left-[260px];
}

.subMenu {
  display: none;
}

.show-subMenu {
  display: block;
  margin-left: 20px;
}

.submenu-item::before,
.submenu-item::after,
.submenu-item:not(:last-child)::after,
.submenu-item:not(:first-child):not(:last-child)::after {
  @apply border-l border-b rounded-bl-lg border-smena_gray-30 block absolute;
  content: '';
  left: -1px;
  z-index: 1;
}

.submenu-item::before,
.submenu-item:not(:last-child)::after {
  top: 50%;
  transform: translate(0, -60%);
}

.submenu-item::before {
  width: 10px;
  height: 10px;
}

.submenu-item::after {
  width: 1px;
  height: 70%;
  top: 0;
  transform: translate(0, -40%);
}

.submenu-item:not(:last-child)::after {
  width: 1px;
  height: 100%;
}

.submenu-item:not(:first-child):not(:last-child)::after {
  width: 1px;
  height: 110%;
  top: 40%;
}

.timelines {
  width: fit-content;
}

.timelines .header {
  display: grid;
  grid-template-columns: 200px repeat(24, 45px);
}

.shiftsTime {
  display: grid;
  height: 40px;
  grid-template-columns: 200px repeat(24, 45px);
}

.sidebar {
  @apply fixed overflow-x-auto top-0 h-full transition-all duration-500 ease-in-out pt-6 px-5 z-[100] bg-smena_white w-[430px];
}

.tooltip {
  @apply z-20 w-auto min-w-max flex justify-center items-center border-0.5 border-smena_gray-40;
}

.tooltip > span {
  flex: 1 0 auto;
}

.tooltip_bottom-left:before {
  content: '';
  position: absolute;
  left: 10%;
  top: -5px;
  /* hard color */
  border: 5px solid transparent;
  border-bottom-color: #f5f8ff;
  margin-top: -5px;
}

.tooltip_top-center .tooltip_top-left:before {
  content: '';
  position: absolute;
  left: 10%;
  bottom: -5px;
  /* hard color */
  border: 5px solid transparent;
  border-top-color: inherit;
  margin-bottom: -5px;
}

.tooltip_top-center:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -5px;
  /* hard color */
  border: 5px solid transparent;
  border-top-color: #f5f8ff;
  margin-bottom: -5px;
}

.tooltip_top-center:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -5px;
  /* hard color */
  border: 5px solid transparent;
  border-top-color: #f5f8ff;
  margin-bottom: -5px;
}

.recharts-tooltip-wrapper {
  @apply outline-none;
}

.tooltip_usual:before {
  content: '';
  position: absolute;
  right: 100%;
  top: 50%;
  /* hard color */
  border: 5px solid transparent;
  border-right-color: inherit;
  margin-top: -5px;
}

.name_shadow {
  box-shadow: 2px 2px 4px rgba(189, 189, 189, 0.25);
}

.stats_shadow {
  box-shadow: -2px 2px 4px rgba(189, 189, 189, 0.25);
}

.menu__element {
  position: relative;
}

.menu__element .menu__link {
  padding-bottom: 10px;
}

.menu__element:not(:last-child) {
  margin-right: 50px;
}

.menu__link {
  @apply disabled:text-smena_text-disabled disabled:cursor-not-allowed;
}

.menu__element-active:after {
  @apply bg-primary;
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  border-radius: 15px 15px 0 0;
}

.filter-bar {
  @apply bg-smena_white p-4 rounded-lg;
}

th,
.th {
  position: relative;
}

.filter__popup {
  @apply absolute top-full translate-y-2 left-0 flex flex-col gap-y-3 p-5 bg-smena_white rounded-lg shadow-smena z-50 w-[305px]
  transition-all;
}

/* Pagination */
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-18lqwt2.Mui-selected {
  @apply rounded-full bg-primary-secondary text-smena_white !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root,
.css-18lqwt2 {
  @apply text-smena_text rounded-full !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

/* Pagination */

.groupContent {
  max-width: 180px;
}

.uploadButton {
  border-width: 3px;
  width: 190px;
  height: 190px;
}

.table__header {
  @apply Table-H1 uppercase text-smena_text-secondary p-4 text-left;
}

.active-column {
  @apply bg-smena_bb-background_light;
}

.table__content {
  @apply Table-small text-smena_text p-4 border-r border-b last:border-r-0 border-smena_gray-5 relative;
}

.add_profession {
  max-width: max-content;
}

.add_profession__table-edit {
  grid-template-columns:
    minmax(250px, 255px) minmax(140px, 150px) minmax(180px, 185px)
    minmax(115px, 120px) minmax(80px, 85px) minmax(115px, 120px)
    minmax(85px, 90px) minmax(130px, 130px) minmax(100px, 100px) minmax(110px, 115px)
    110px;
}

.add_profession-facility {
  grid-template-columns:
    minmax(200px, 205px) minmax(140px, 150px) minmax(180px, 185px)
    minmax(115px, 120px) minmax(150px, 160px) minmax(115px, 120px)
    minmax(130px, 140px) minmax(100px, 100px) minmax(150px, 160px) minmax(110px, 115px)
    minmax(150px, 160px);
}

.add_profession-group {
  grid-template-columns:
    minmax(200px, 205px) minmax(170px, 175px) minmax(170px, 175px) minmax(140px, 150px) minmax(180px, 185px)
    minmax(115px, 120px) minmax(150px, 160px) minmax(105px, 110px)
    minmax(120px, 130px) minmax(100px, 100px) minmax(150px, 160px) minmax(110px, 115px)
    minmax(140px, 160px);
}

.add_profession__table-view {
  grid-template-columns:
    minmax(250px, 255px) minmax(140px, 150px) minmax(180px, 185px)
    minmax(115px, 120px) minmax(80px, 85px) minmax(115px, 120px)
    minmax(85px, 90px) minmax(130px, 130px) minmax(100px, 100px) minmax(120px, 120px);
}

.add_profession__name {
  max-width: 220px;
}

.add_profession__paymentType {
  max-width: 110px;
}

.add_profession__rate {
  width: 80px;
}

.breadcrumbs {
  @apply md:mb-[10px];
}

.breadcrumbs .breadcrumb {
  margin-right: 5px;
}

.breadcrumbs .breadcrumb:not(:last-child)::after {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url('../assets/svg/breadcrumbArrow.svg');
  margin-left: 5px;
}

/* Moderation page */

.moderation__status {
  @apply flex justify-center items-center;
  width: 90px;
  height: 23px;
  border-radius: 54px;
}

.minus[type='checkbox']:not(:checked) {
  @apply bg-primary-secondary bg-[length:50%] bg-no-repeat bg-center;
  background-image: url('../assets/svg/minus.svg');
}

/* Period page */
.period {
  @apply bg-smena_white rounded-lg;
}

.shiftModerationStatus {
  @apply flex items-center rounded gap-x-2 px-1 py-0.5;
}

.employer__mark,
.employer__mark-unread {
  @apply absolute w-0 h-0 z-10 rotate-45;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  filter: drop-shadow(0px 0px 1px white);
}

.employer__mark {
  border-bottom: 6px solid #393b55;
}

.employer__mark-unread {
  border-bottom: 6px solid #ff555f;
}

.hoursCount {
  @apply font-inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.15px;
}

.radio-block {
  @apply col-span-1 px-3 py-[6px] flex items-center border gap-x-3 Body2 cursor-pointer;
}

.input-primary[type='search'] {
  @apply Body2;
}

[type='search'] {
  font-size: unset;
}

[type='radio']:focus {
  box-shadow: none;
}

.radio {
  @apply relative w-5 h-5 checked:after:content-[''] border-none bg-smena_bb-background outline-none;
}

.radio:checked::after {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 inline-block w-[10px] h-[10px] rounded-full bg-smena_white;
}

.radio_primary {
  @apply checked:bg-primary checked:hover:bg-primary checked:focus:bg-primary;
}

.radio_approve {
  @apply checked:bg-smena_green-mark checked:hover:bg-smena_green-mark checked:focus:bg-smena_green-mark;
}

.radio_reject {
  @apply checked:bg-smena_red-mark checked:hover:bg-smena_red-mark checked:focus:bg-smena_red-mark;
}

.moderation__header {
  @apply sticky top-0 z-20 flex items-center border-b border-smena_gray-30 h-[40px] Caption bg-smena_white;
}

.row-days__days-num {
  @apply grid overflow-x-auto bg-smena_white;
  scrollbar-width: none;
}

.row-days__days-num::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.table__stats {
  @apply grid justify-items-end text-smena_text-secondary gap-x-[5px] Caption-small h-[30px];
}

.table__stats-columns {
  @apply grid grid-cols-[repeat(7,_55px)_60px_55px];
}

.table__stats-columns_promotion {
  @apply grid-cols-[repeat(7,_55px)_60px_65px_55px];
}

.period__event {
  @apply inline-grid border-0.5 rounded border-smena_gray-50 w-full h-full;
}

.period__text {
  @apply flex justify-center items-center cursor-pointer w-[35px] h-[20px];
}

.sidebar__shift {
  @apply grid rounded-lg gap-x-[10px];
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.13);
}

.search__user {
  @apply w-full flex flex-col justify-center items-center gap-y-4;
  height: calc(100% - 100px);
}

.smena-bubble {
  @apply py-1 px-3 rounded-lg flex items-center gap-x-1
  bg-smena_bb-background Tag text-primary;
}

.smena-bubble_disabled {
  @apply py-1 px-3 rounded-lg flex items-center gap-x-1
  bg-smena_gray-30 Tag text-smena_text-secondary;
}

/* antd */

.custom__picker {
  @apply font-inter text-smena_text rounded-lg text-[14px] leading-[143%] tracking-[0.15px] h-[32px] w-full border border-smena_gray-40;
}

.ant-picker-focused {
  @apply border-primary shadow-none;
}

.ant-picker-clear {
  @apply flex justify-center items-center w-[18px] h-[18px];
}

.custom__picker .ant-picker-input > input {
  @apply Body2 text-smena_text;
}

.custom__picker .ant-picker-input > input[disabled] {
  @apply text-smena_text-secondary;
}

.custom__picker .ant-picker-input > input::placeholder {
  @apply text-smena_text-helper;
}

.overlay {
  @apply absolute top-0 left-0 z-50 w-full h-full bg-smena_gray-90 opacity-20;
}

.add_employer {
  @apply rounded-lg overflow-hidden bg-smena_white min-h-max max-h-[450px];
}

.ozon-moderation__table {
  @apply grid grid-cols-[minmax(auto,_250px),_minmax(10px,_65px)] gap-x-1;
}

.ozon__import-error {
  @apply bg-smena_white p-5 rounded-lg flex flex-col w-[700px] h-[750px] shadow-smena;
}

.ozon__import-error_table {
  @apply grid gap-x-5;
  height: 56px;
  grid-template-columns: repeat(2, 260px) auto;
}

.ozon__import-error_table_block {
  @apply overflow-y-auto max-h-[600px];
}

.switch_btn {
  @apply relative inline-block w-8 h-4;
}

/* Hide default HTML checkbox */
.switch_btn input,
.slide_switch input {
  @apply opacity-0 w-0 h-0;
}

/* The slider */
.switch_btn .slider {
  @apply absolute cursor-pointer bg-smena_bb-background rounded-xl;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
}

.switch_btn .slider:before {
  @apply absolute h-3 w-3 bg-smena_white rounded-full;
  content: '';
  left: 2px;
  bottom: 2px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch_btn input:checked + .slider {
  @apply bg-primary;
}

.switch_btn input:disabled + .slider {
  @apply cursor-not-allowed;
}

.switch_btn input:checked + .slider:before {
  transform: translateX(16px);
}

.slide_switch {
  @apply relative inline-block w-12 h-6;
}

.slide_switch .slider {
  @apply absolute cursor-pointer bg-smena_white bg-opacity-20 rounded-full outline outline-smena_white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
}

.slide_switch .slider:before {
  @apply absolute h-[17px] w-[17px] bg-smena_white rounded-full top-[3.5px] left-1;
  content: '';
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slide_switch input:checked + .slider {
  @apply bg-smena_white bg-opacity-50;
}

.slide_switch input:checked + .slider:before {
  @apply transform translate-x-6;
}

.documents .data-block {
  width: 420px;
}

.documents .photo-block {
  @apply w-[200px];
}

.documents .approve-block {
  width: 220px;
}

.documents .card-block {
  width: 290px;
}

.documents .card-block .cardInputData {
  @apply rounded-md bg-smena_bb-background_base font-inter;
  font-weight: 400;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.15px;
}

.documents .card-block .cardInputData::placeholder {
  @apply text-smena_bb-border;
}

.documents .bank-block {
  width: auto;
}

.documents .approve-block .approve-radio {
  @apply rounded-lg border flex items-center gap-3;
  padding: 3.5px 12px;
}

.documents .save-block {
  max-width: 580px;
}

.documents .upload_button {
  @apply border w-full border-dashed rounded-md border-smena_bb-border
    overflow-hidden flex justify-center items-center;
  height: 200px;
}

.documents .main-pages {
  @apply 2xl:w-[1070px] w-[980px] max-w-full;
}

.documents .medical {
  @apply 2xl:w-[405px] w-[395px];
}

.documents .main-pages .seria {
  width: 80px;
}

.documents .main-pages .number {
  width: 130px;
}

.documents .main-pages .get-date {
  width: 150px;
}

.error-message {
  @apply text-smena_red inline-flex pt-1 max-h-10;
}

.approve_radio {
  @apply bg-smena_gray-5 border border-transparent;
}

.approve_radio:checked,
.approve_radio:checked:hover,
.approve_radio:checked:focus {
  background: white;
  position: relative;
}

.approve_reject:checked,
.approve_reject:checked:hover,
.approve_reject:checked:focus {
  @apply border-smena_red;
}

.approve_penalty:checked,
.approve_penalty:checked:hover,
.approve_penalty:checked:focus {
  @apply border-primary;
}

.approve_reject:checked::after {
  @apply bg-smena_red-mark;
}
.approve_approve:checked::after {
  @apply bg-smena_green-mark;
}
.approve_penalty:checked::after {
  @apply bg-primary;
}
.userMainInfo {
  max-width: max-content;
}

.chart-filter {
  width: 160px;
}

.trendsBlock {
  @apply grid grid-cols-12 gap-x-5 gap-y-8 shadow-smena rounded-lg bg-smena_white p-6;
}

/* loader */
.loader-ring {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}
.loader-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 2px;
  border: 2px solid #397dff;
  border-radius: 50%;
  animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loader-ring div {
  border-color: #397dff transparent transparent transparent;
}

.loader-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.recharts-cartesian-axis-tick-value {
  @apply font-roboto text-smena_text;
  font-weight: 400;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.15px;
}

.recharts-default-tooltip {
  @apply border-0.5 border-smena_gray-5 rounded shadow-smena;
}
.recharts-tooltip-label {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.15px;
}
.recharts-tooltip-item {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.15px;
}
.recharts-tooltip-item-value {
  margin-left: 4px;
}

.groupName {
  @apply overflow-hidden overflow-ellipsis text-smena_text;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.requestGraphic {
  display: grid;
  grid-template-columns: 200px minmax(0, max-content);
}

.requestScheduleProfessions {
  overflow-y: auto;
  max-height: 500px;
}

.requestScheduleDates {
  overflow: auto;
  max-height: 520px;
}

.requestScheduleProfessionsRows {
  @apply p-2 grid grid-rows-[32px] grid-flow-col;
}

.requestRows {
  padding: 11px 0;
  display: grid;
  grid-template-rows: 20px;
  grid-auto-flow: column;
}

.graphigRows {
  padding: 4px 0;
  display: grid;
  grid-template-rows: 20px;
  grid-auto-flow: column;
}

.graphigRowsData {
  padding: 4px 0;
  display: grid;
}

.requestGraphicCellDates {
  @apply bg-smena_bb-background rounded;
}

.graphicSerchUserHeader {
  display: grid;
  grid-template-columns: 230px 80px;
}
.graphicSearchUserTable {
  display: grid;
  grid-template-columns: 200px 115px;
}

.blockTemplate {
  @apply p-6 rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5;
}

.blockTitleTemplate {
  @apply Subtitle1 text-smena_text-secondary col-span-full;
}

.groupPageBlock {
  @apply 2xl:col-span-7 2xl:col-start-1 xl:col-span-8 lg:col-span-10 col-span-full;
}

.userPageBlock {
  @apply 2xl:col-span-7 2xl:col-start-1 xl:col-span-8 lg:col-span-10 col-span-full;
}

.facilityPageBlock {
  @apply 2xl:col-span-8 2xl:col-start-1 xl:col-span-9 md:col-span-10 col-span-full;
}

.vacancyPageBlock {
  @apply xl:col-span-8 lg:col-span-10 col-span-full;
}
.storiesPageBlock {
  @apply 2xl:col-span-4 2xl:col-start-1 xl:col-span-5 lg:col-span-10 col-span-full;
}

.requestPageBlock {
  @apply xl:col-span-9 lg:col-span-10 md:col-span-11 col-span-full;
}
.rateBlockAuto {
  display: grid;
  grid-template-columns: minmax(max-content, 150px) 135px;
}

.independentBlock {
  @apply col-span-full p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5;
}

.requestRow:hover {
  @apply bg-smena_bb-background_base;
}

.rate-block {
  @apply grid justify-between grid-cols-[repeat(1,_max-content)] gap-y-5 md:grid-cols-[repeat(2,_max-content)];
}

.scheduleRequestsTable {
  max-height: 355px;
  overflow-x: hidden;
  overflow-y: auto;
}

.scheduleHasWorkers {
  grid-template-columns: min-content 1fr;
}

.react-viewer-inline > .react-viewer-mask {
  background-color: transparent;
}
.react-viewer-btn[data-key='prev'],
.react-viewer-btn[data-key='reset'],
.react-viewer-btn[data-key='next'] {
  display: none;
}

.even-row {
  background: #f9f9fb;
}

.custom-quill .ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.custom-quill .ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
