.ant-picker-dropdown .ant-picker-time-panel-column::after {
  display: none;
}
.ant-picker-footer {
  display: none;
}
.ant-btn-primary {
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgb(5 145 255 / 10%);
}
.ant-select .ant-select-clear {
  margin-top: -6px;
  width: 16px;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  @apply hover:border-primary active:right-0;
}
.ant-select-multiple .ant-select-selection-item {
  @apply bg-smena_bb-background rounded-2xl text-primary-dark items-center
  font-normal text-[12px] leading-[20px] tracking-[0.4px];
}
.rc-virtual-list-holder-inner {
  @apply gap-y-1;
}

.ant-select-item-option-content {
  @apply text-smena_text;
}

.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  @apply bg-smena_bb-background_light;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply bg-smena_bb-background;
}
.ant-select-dropdown
  .ant-select-item-option-active.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply bg-smena_bb-border_light;
}
.ant-select-item-option-state {
  @apply flex items-center;
}
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next {
  @apply inline-flex items-center justify-center rounded-full max-h-8 max-w-[32px] h-8 w-8
  hover:bg-smena_gray-5 disabled:text-primary-dark;
}
.ant-pagination .ant-pagination-prev button,
.ant-pagination .ant-pagination-next button {
  @apply text-primary disabled:text-smena_text-disabled;
}
.ant-pagination-item {
  @apply font-bold text-[14px] leading-[24px];
}
.ant-pagination .ant-pagination-item-active {
  @apply rounded-full bg-primary border-none;
}
.ant-pagination .ant-pagination-item-active a {
  @apply text-smena_white;
}
.ant-pagination .ant-pagination-item-active:hover a {
  @apply text-smena_white;
}
.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
  @apply bg-smena_bb-background rounded-full;
}

.internship-period .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.internship-period .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.internship-period .ant-picker-panels > *:last-child {
  display: none;
}

.internship-period .ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.internship-period .ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}
.ant-tooltip .ant-tooltip-arrow:before {
  @apply bg-smena_text;
}

.ant-tooltip .ant-tooltip-inner {
  @apply bg-smena_text px-3 py-2 rounded-lg shadow-none
  text-smena_white font-roboto font-normal text-[13px] leading-[20px] tracking-[0.15px];
}

.ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
  @apply ring-0;
}

.ant-pagination .ant-pagination-item a {
  @apply text-smena_text;
}

.ant-pagination .ant-pagination-item-active a {
  @apply text-smena_white;
}

.ant-select-dropdown .ant-select-item-option-disabled {
  @apply bg-smena_gray-5 opacity-30;
}

.ant-modal {
  @apply w-[500px] font-normal text-[15px] leading-[24px] tracking-[0.15px] font-roboto text-smena_text
    top-1/2 -translate-y-1/2;
}

.ant-modal .ant-modal-title {
  @apply font-medium text-[20px] leading-[32px] tracking-[0.15px] text-smena_text;
}

.ant-modal .ant-modal-body {
  @apply text-[15px];
}

.ant-modal .ant-modal-footer {
  @apply mt-5;
}

.ant-modal .ant-btn {
  @apply font-inter rounded-lg cursor-pointer
  transition font-bold
  disabled:opacity-50 disabled:cursor-not-allowed text-[14px] py-1 px-6 h-8;
}

.ant-modal .ant-btn.ant-btn-default {
  @apply bg-white text-primary hover:bg-smena_bb-background active:bg-smena_bb-border;
  border: 1px solid rgba(27, 96, 227, 0.3);
}

.ant-modal .ant-btn.ant-btn-primary {
  @apply bg-primary text-white hover:bg-primary-secondary active:bg-primary-dark;
}

.ant-modal .ant-btn.ant-btn-dangerous {
  @apply bg-smena_red text-white hover:bg-smena_red-light active:bg-smena_red-dark;
}

.ant-modal .ant-btn.ant-btn-default.ant-btn-dangerous {
  @apply bg-smena_white text-smena_red hover:bg-smena_red-extra_light active:bg-smena_red-light;
  border: 1px solid rgba(255, 85, 95, 0.3);
}

.ant-carousel .slick-track {
  display: flex;
  align-items: center;
}

.slick-arrow {
  z-index: 10000;
}

.slick-arrow > svg {
  background-color: rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: #fff;
  fill: #fff;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-carousel .slick-prev {
  left: 10%;
}

.ant-carousel .slick-next {
  right: 10%;
}

.ant-carousel .slick-next::before,
.ant-carousel .slick-prev::before {
  content: '';
}
