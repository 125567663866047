@font-face {
    font-family: SF-Pro-Text;
    src: url('fonts/SF Pro Text/SF-Pro-Text-Regular.otf');
}

@font-face {
    font-family: SF-Pro-Display;
    src: url('fonts/SF Pro Text/SF-Pro-Display-Bold.otf');
}

@font-face {
    font-family: SF-Pro-Text-SemiBold;
    src: url('fonts/SF Pro Text/SF-Pro-Text-Semibold.otf');
}
